import React from 'react';

import './index.css';

// Create a function that uses Webpack's require.context to load all images in a folder
function importAll(r: any) {
  const images: { [key: string]: string } = {};
  r.keys().forEach((key: string) => {
    images[key] = r(key);
  });
  return images;
}

// @ts-ignore context is available may need to update the require package
const imageContext = require.context(
  '../../assets/images/gallery',
  false,
  /\.(png|jpg|jpeg|gif|svg|JPG|mp4)$/,
);
const images: { [key: string]: string } = importAll(imageContext);

export const Gallery = () => {
  return (
    <section className="gallery-parent" style={{ padding: '32px' }}>
      {Object.values(images).map((i: string, ix: number) => {
        if (i.endsWith('mp4')) {
          return (
            <video
              width="320"
              height="240"
              loop
              preload="auto"
              autoPlay
              muted
              playsInline
              key={`gallery-image-${ix}`}>
              <source src={i} type="video/mp4" />
            </video>
          );
        }
        return (
          <img className="gallery-image" src={i} key={`gallery-image-${ix}`} />
        );
      })}
    </section>
  );
};
