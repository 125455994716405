import React, { useState } from 'react';
import { signInWithPopup, signOut, User } from 'firebase/auth';

import { auth, googleProvider } from '../../utils';

const USER_EMAILS = ['mehul.ajmer97@gmail.com', 'khushi551997@gmail.com'];

type todo = {
  isChecked: boolean;
  text: string;
  id: number;
};

export const Admin = () => {
  const [todos, setTodos] = useState<todo[]>([]);

  // const deleteUser = (user: User) => {
  //   signOut(auth).then(() => {
  //     user.delete().then(() => {
  //       console.log('user deleted');
  //     });
  //   });
  // };
  // const signInWithGoogle = async () => {
  //   signInWithPopup(auth, googleProvider)
  //     .then((gUser) => {
  //       console.log('google login res', gUser);
  //       // !USER_EMAILS.includes(gUser?.user?.email ?? '') &&
  //       //   deleteUser(gUser.user);
  //     })
  //     .catch((err) => {
  //       console.log('google login err', err);
  //     });
  // };

  return (
    <div>
      Todo:
      {todos.map((todo) => (
        <div>
          <div
            style={{ height: '8px', width: '8px', border: '1px solid black' }}
          />
          <div>{todo.text}</div>
          <div>{todo.isChecked}</div>
        </div>
      ))}
    </div>
  );
};
