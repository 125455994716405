import React from 'react';

import Carnival from '../../assets/images/carnival.jpeg';
import RingCeremony from '../../assets/images/ringCeremony.jpeg';
import Barat from '../../assets/images/barat.jpeg';

const RitualsData = [
  {
    src: 'https://media.tenor.com/OAJYn2prWKsAAAAC/%E0%A4%B6%E0%A5%8D%E0%A4%B0%E0%A5%80%E0%A4%97%E0%A4%A8%E0%A5%87%E0%A4%B6%E0%A4%BE%E0%A4%AF%E0%A4%A8%E0%A4%AE-happy-ganesh-chaturthi.gif',
    date: (
      <p>
        3<sup>rd</sup>December 2023 - 9:00 AM
      </p>
    ),
    message: `“Bless us Ganesha with your presence, clear our sorrows and enhance our happiness”`,
    event: 'Vinayak Sthapana (Baan)',
  },
  {
    src: Carnival,
    date: (
      <p>
        3<sup>rd</sup>December 2023 - 11:00 AM
      </p>
    ),
    message: `“A Whimsical journey commences with laughter, blooms and unbridled Joy”`,
    event: 'Wedding Carnival',
  },
  {
    src: RingCeremony,
    date: (
      <p>
        3<sup>rd</sup>December 2023 - 5:00 PM
      </p>
    ),
    message: `“Destiny brought us together, and now love bands will seal our bond forever……”`,
    event: 'Ring Ceremony',
  },
  {
    src: 'https://media.tenor.com/K8tmCZSrqrAAAAAd/udaariyaan-tejo-sandhu.gif',
    date: (
      <p>
        3<sup>rd</sup>December 2023 - 7:30 PM
      </p>
    ),
    message: `“The wedding bells are about to ring in the moment of happiness Let's dance and sing”`,
    event: 'Sangeet',
  },
  {
    src: 'https://media.tenor.com/FXoaruNTFjwAAAAd/vivek-vivek-dahiya.gif',
    date: (
      <p>
        4<sup>th</sup>December 2023 - 7:30 AM
      </p>
    ),
    message: `“The sun will shine and the flowers will bloom as you celebrate the Haldi of Bride and Groom”`,
    event: 'Haldi',
  },
  {
    src: 'https://assets-v2.lottiefiles.com/a/a4e9f9aa-1171-11ee-b9be-8735f95230dc/tJhFkJL8CC.gif',
    date: (
      <p>
        4<sup>th</sup>December 2023 - 11:30 AM
      </p>
    ),
    message: `“Vessel of divine will reflect culture and traditions we all Contain”`,
    event: 'Chaak- Bhaat',
  },
  {
    src: Barat,
    date: (
      <p>
        4<sup>th</sup>December 2023 - 8:00 PM
      </p>
    ),
    message: `“Singing and dancing barati's will come to Witness their heartily welcome”`,
    event: 'Baraat Swagat',
  },
];

export const Rituals = () => {
  return (
    <>
      {RitualsData.map(({ src, date, message, event }, ix) => (
        <div key={`Ritual-item-${ix}`} id="ritual-container">
          <img src={src} />
          <div>
            <p className="tt-chocolate-font">{date}</p>
            <p className="tt-chocolate-font">{event}</p>
            <p className="dancing-script ritual-message">{message}</p>
          </div>
        </div>
      ))}
      <div style={{ margin: '48px 0' }}>
        <div style={{ display: 'inline-flex' }}>
          <p className="angella-font" style={{ fontSize: '56px' }}>
            Phere
          </p>
          <p
            className="tt-chocolate-font"
            style={{
              fontSize: '16px',
              alignSelf: 'center',
              textDecorationLine: 'none',
            }}>
            {`--( Midnight)`}
          </p>
        </div>
        <img
          src={'https://media.tenor.com/_O2W980scdgAAAAC/udaariyaan-fatejo.gif'}
          style={{
            width: '100%',
            height: '250px',
            objectFit: 'fill',
            borderRadius: '5px',
          }}
        />
        <p
          className="angella-font"
          style={{ paddingTop: '16px', fontSize: '36px' }}>
          "A golden chapter of two hearts will begin with a pious knot and seven
          sacred vows"
        </p>
      </div>
    </>
  );
};
