import React from 'react';
import Banner from '../../assets/images/new-landing-page/image.jpg';

export const NewLandingPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '24px',
      }}>
      <img
        src={Banner}
        alt={''}
        style={{
          width: '100%',
          maxWidth: '600px',
          borderRadius: '8px',
          maxHeight: '80vh',
          overflow: 'hidden',
        }}
      />
      <audio controls loop autoPlay style={{ marginTop: '20px' }}>
        <source
          src={`${process.env.PUBLIC_URL}/song.mp3`}
          type="audio/mp3"></source>
      </audio>
    </div>
  );
};
