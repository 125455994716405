import React, { useEffect } from 'react';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

const promises = [
  'In kookoo I will talk calmly, with care and will take care of you',
  'I will become the big spoon',
  `I will press you head when it's aching`,
  'Since I get influenced by my environment, I will handle it myself, if not then seek help from outside and after trying everything I will come to you',
  'I will become solutions oriented',
  'I we fight or argue, I will first come to you and talk about it and try to comfort you',
  'I share all my clothes with you',
  'I will give you full massages after learning it from professionals',
  'If you get angry on anything I will calm you down',
  'When the company is opened, I will get everything done',
  'I will massage your hands and legs',
  'I will move my hand on your head while you are sleeping or when you are unable to sleep',
  'And no EGO',
];

const firebaseConfig = {
  apiKey: 'AIzaSyD8dAv7lBCx4nRjqTtdZ4rOmD5-7wy0IKw',
  authDomain: 'mota-9e3d4.firebaseapp.com',
  databaseURL: 'https://mota-9e3d4-default-rtdb.firebaseio.com',
  projectId: 'mota-9e3d4',
  storageBucket: 'mota-9e3d4.appspot.com',
  messagingSenderId: '973339128694',
  appId: '1:973339128694:web:2888266da7c09d5a418057',
  measurementId: 'G-TB6QHHJQWF',
};

export const Promises = () => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  useEffect(() => {
    logEvent(analytics, 'page_viewed');
  }, []);

  return (
    <>
      <h3 style={{ padding: '20px 10px 10px 10px', textAlign: 'center' }}>
        A list of promises that I will keep for the rest of my life
      </h3>
      <ul style={{ paddingTop: '20px' }}>
        {promises.map((i) => (
          <li>{i}</li>
        ))}
        <img
          src="https://media1.tenor.com/m/pg68op-nERsAAAAC/begging-hamster-hamster.gif"
          alt="Begging Hamster Hamster Beg GIF - Begging Hamster Hamster Beg GIFs"
          style={{
            width: '90%',
            height: '90%',
            marginTop: '20px',
          }}
        />
        <h2 style={{ textAlign: 'center' }}>Please Stay</h2>
      </ul>
    </>
  );
};
